import { useEffect, useState } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Loader,
  SnackbarBanner,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getAsyncRequest, InitState, useUserState } from "gx-npm-lib";
import { TaskObject, TasksApiPayload, TaskStatus } from "../../types";
import NoTasksAssigned from "../survey-card/no-tasks-assigned.component";
import SurveyCards from "../survey-card/survey-cards.component";
import styles from "./tasks-dashboard.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const TasksDashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { firstName } = useUserState();
  const [isError, setIsError] = useState<boolean>(false);
  const [isTasksDataLoading, setIsTasksDataLoading] = useState<boolean>(true);
  const [tasks, setTasks] = useState<Array<TaskObject>>([]);
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);

  useEffect(() => {
    (async () => {
      const response = await getAsyncRequest("/api/v2/user/tasks");
      if (response?.status === 200 && Array.isArray(response.data?.data)) {
        const tasksFromApi = response.data.data.map((data: TasksApiPayload) => {
          return {
            initId: data.initId || "",
            initProductId: data.initProductId || "",
            initState: data.initState || InitState.ACTIVE,
            requestedByCompanyName: data.requestedByCompanyName || "",
            requestedByUserName: data.requestedByUserName || "",
            surveyId: data.surveyId || "",
            surveyStatus: data.surveyStatus || TaskStatus.NOT_STARTED,
          };
        });
        setTasks(tasksFromApi);
      } else {
        setIsError(true);
      }
      setIsTasksDataLoading(false);
    })();
  }, []);

  return (
    <div className={classNames("has-main-nav", classes.containerTasksDashboard)}>
      <div className={classes.contentWrapper}>
        <div className={classNames(classes.header)}>
          {!isTasksDataLoading && (
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent
                  styling={"h5"}
                  color={"gartnerBlue"}
                  rootClassName={classNames(
                    classes.commonPadding,
                    classes.title,
                    isGCOM3606FF && classes.titleGCOM3606,
                    classes.spacing
                  )}
                >
                  {t("Welcome back")}
                  {!!firstName && `, ${firstName}`}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent
                      styling={"h5"}
                      rootClassName={classNames(
                        classes.commonPadding,
                        classes.title,
                        isGCOM3606FF && classes.titleGCOM3606,
                        classes.spacing
                      )}
                    >
                      {t("Welcome back")}
                      {!!firstName && `, ${firstName}`}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <h5 className={classNames(classes.commonPadding, classes.title, classes.spacing)}>
                      {t("Welcome back")}
                      {!!firstName && `, ${firstName}`}
                    </h5>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
        </div>
        <div className={classNames(classes.commonPadding, classes.spacing)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classes.tasksWrapperGCOM3606}>
                <TypographyComponent styling={"h3"} color={"carbon"}>
                  {t("My tasks")}
                </TypographyComponent>
              </div>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <h3 className={classes.tasksWrapper}>{t("My tasks")}</h3>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div className={classNames(classes.commonPadding, classes.spacing)}>
          {isTasksDataLoading && (
            <div className={classNames(classes.loadingContainer)}>
              <Loader />
            </div>
          )}
          {!isTasksDataLoading && (
            <div>
              {tasks.length !== 0 && <SurveyCards tasks={tasks} />}
              {tasks.length === 0 && <NoTasksAssigned />}
            </div>
          )}
        </div>
        <SnackbarBanner isOpen={isError} isDefaultErrorMessage={true} type="ERROR" setIsOpen={setIsError} />
      </div>
    </div>
  );
};
export default TasksDashboard;
