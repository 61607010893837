import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import styles from "./survey-card-status.styles";
import { TaskStatus } from "../../types";
import { InitState } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

type SurveyCardStatusProps = { initState: InitState; status: TaskStatus };
const useStyles = makeStyles(() => styles);
const SurveyCardStatus: FC<SurveyCardStatusProps> = ({ initState, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);
  return (
    <div className={classes.container}>
      <div className={classes.textStateWrapper}>
        <div className={classNames(classes.textContainer, isGCOM3606FF && classes.textContainerGCOM3606)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"sweetHuckleberry"} styling={"p4"} boldness={"semi"}>
                {initState !== InitState.ACTIVE && t("Questionnaire closed")}
                {initState === InitState.ACTIVE && (
                  <Fragment>
                    {status === TaskStatus.NOT_STARTED && t("Not started")}
                    {status === TaskStatus.IN_PROGRESS && t("In progress")}
                    {status === TaskStatus.SUBMITTED && t("Responses shared")}
                  </Fragment>
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className="p4 semi-bold">
                {initState !== InitState.ACTIVE && t("Questionnaire closed")}
                {initState === InitState.ACTIVE && (
                  <Fragment>
                    {status === TaskStatus.NOT_STARTED && t("Not started")}
                    {status === TaskStatus.IN_PROGRESS && t("In progress")}
                    {status === TaskStatus.SUBMITTED && t("Responses shared")}
                  </Fragment>
                )}
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
    </div>
  );
};

export default SurveyCardStatus;
