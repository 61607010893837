import { colorPalette } from "gx-npm-common-styles";

const SurveyCardStatusStyle = {
  container: {
    alignItems: "flex-start",
    borderTopLeftRadius: "8px",
    display: "flex",
    height: "53px",
    overflow: "hidden",
    position: "relative" as const,
    width: "max-content",
    "& .gx-selected-vendor-icon": {
      marginRight: "33px",
      "& svg": {
        left: "0px",
        position: "absolute" as const,
        top: "-18px",
        width: "33px",
      },
    },
  },
  textContainer: {
    alignItems: "center",
    background: "rgba(0, 40, 86, 0.1)",
    borderBottomRightRadius: "8px",
    borderTopLeftRadius: "8px",
    color: colorPalette.accent.sweetHuckleberry.hex,
    display: "flex",
    height: "32px",
    justifyContent: "center",
    padding: "8px 12px",
  },
  updatedDateWrapper: {
    "&&": {
      alignItems: "center",
      color: colorPalette.neutrals.iron.hex,
      display: "flex",
      paddingLeft: "18px",
    },
  },
  statusIcon: {
    height: "15px",
    paddingRight: "10px",
  },
  textStateWrapper: {
    alignItems: "center",
    display: "flex",
  },
};

export default SurveyCardStatusStyle;
