import classNames from "classnames";
import SurveyCardItem from "./survey-card-item.component";
import { TaskObject } from "../../types";

type SurveyCardsProps = { tasks: Array<TaskObject> };
const SurveyCards: React.FC<SurveyCardsProps> = ({ tasks }) => {
  return (
    <div className={classNames("eval-cards")}>
      {tasks.map((item, index) => {
        return (
          <SurveyCardItem
            key={item.surveyId}
            initId={item.initId}
            initProductId={item.initProductId}
            initState={item.initState}
            isLastCard={index === tasks.length - 1}
            requestedByCompanyName={item.requestedByCompanyName}
            requestedByUserName={item.requestedByUserName}
            surveyStatus={item.surveyStatus}
          />
        );
      })}
    </div>
  );
};

export default SurveyCards;
