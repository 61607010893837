import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import styles from "./survey-card-status.styles";
import { TaskStatus } from "../../types";
import { InitState } from "gx-npm-lib";

type SurveyCardStatusProps = { initState: InitState; status: TaskStatus };
const useStyles = makeStyles(() => styles);
const SurveyCardStatus: FC<SurveyCardStatusProps> = ({ initState, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.textStateWrapper}>
        <div className={classes.textContainer}>
          <p className="p4 semi-bold">
            {initState !== InitState.ACTIVE && t("Questionnaire closed")}
            {initState === InitState.ACTIVE && (
              <Fragment>
                {status === TaskStatus.NOT_STARTED && t("Not started")}
                {status === TaskStatus.IN_PROGRESS && t("In progress")}
                {status === TaskStatus.SUBMITTED && t("Responses shared")}
              </Fragment>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SurveyCardStatus;
