import { makeStyles } from "@material-ui/core";
import * as singleSpa from "single-spa";
import classNames from "classnames";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import SurveyClipboard from "../../assets/images/survey-clipboard.svg";
import styles from "./survey-card-item.styles";
import SurveyCardStatus from "./survey-card-status.component";
import { TaskStatus } from "../../types";
import { InitState, UUID } from "gx-npm-lib";
import { FC } from "react";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

type SurveyCardItemProps = {
  initId: UUID;
  initProductId: UUID;
  initState: InitState;
  isLastCard: boolean;
  requestedByCompanyName: string;
  requestedByUserName: string;
  surveyStatus: TaskStatus;
};
const useStyles = makeStyles(() => styles);
const SurveyCardItem: FC<SurveyCardItemProps> = ({
  initId,
  initProductId,
  initState,
  isLastCard,
  requestedByCompanyName,
  requestedByUserName,
  surveyStatus,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);
  const navigateToSurvey = () => {
    singleSpa.navigateToUrl(`/s/questionnaire/${initId}/product/${initProductId}/overview`);
  };
  return (
    <div className={classNames(classes.container, isLastCard && "is-last")}>
      <SurveyCardStatus initState={initState} status={surveyStatus} />
      <div className={classes.statusContainer}>
        <div className={classes.item}>
          <div className={classes.clipBoardAndInfoWrapper}>
            <img src={SurveyClipboard} alt="survey clipboard" />
            <div className={classes.infoWrapper}>
              <div className={classNames(isGCOM3606FF ? classes.nameGCOM3606 : classes.name)}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent color={"carbon"} element={"p"}>
                      {requestedByCompanyName}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <p>{requestedByCompanyName}</p>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
              <div className={classNames(isGCOM3606FF ? "" : classes.title)}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent styling={"p4"} color={"coal"} rootClassName={classes.titleGCOM3606}>
                      {t("Requested by")}
                      {` `}
                      <TypographyComponent styling={"p4"} boldness={"semi"} element={"span"} color={"coal"}>
                        {requestedByUserName}
                      </TypographyComponent>
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <p>
                      {t("Requested by")}
                      {` `}
                      <span>{requestedByUserName}</span>
                    </p>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.item}>
          <Button onClick={navigateToSurvey} rootClassName={classNames("btn-primary", "gx-dragon-continue-btn")}>
            {t("OPEN QUESTIONNAIRE")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurveyCardItem;
