import { colorPalette, weightPalette } from "gx-npm-common-styles";

const surveyCardItemStyles = {
  container: {
    background: colorPalette.basic.white.hex,
    borderRadius: 8,
    margin: "0 0 24px 0",
    minHeight: "156px",
    "&.is-last": {
      marginBottom: "24px",
    },
  },
  statusContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 0,
    padding: "0px 32px 0px 40px",
  },
  item: {
    padding: 0,
  },
  name: {
    color: colorPalette.neutrals.carbon.hex,
    height: 18,
    "& p": {
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      letterSpacing: "0.25px",
      lineHeight: "150%",
    },
  },
  nameGCOM3606: {
    height: 18,
  },
  clipBoardAndInfoWrapper: {
    alignItems: "center",
    display: "flex",
  },
  infoWrapper: {
    marginLeft: 20,
  },
  title: {
    "& p": {
      color: colorPalette.neutrals.coal.hex,
      fontSize: 12,
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
      letterSpacing: "0.35px",
      lineHeight: "150%",
      marginTop: 4,
      paddingRight: 32,
    },
    "& span": {
      fontSize: 12,
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      letterSpacing: "0.35px",
      lineHeight: "150%",
    },
  },
  titleGCOM3606: {
    marginTop: 4,
    paddingRight: 32,
  },
  middleContainer: {
    display: "flex",
  },
  gridItemLast: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  middleGridItem: {
    alignItems: "center",
    display: "flex",
  },
  middleGridItemLabel: {
    color: colorPalette.neutrals.iron.hex,
    paddingBottom: 8,
  },
  middleGridItemValue: {
    color: colorPalette.neutrals.carbon.hex,
  },
  ctaWrapper: {
    paddingRight: 8,
  },
  stateOptionWrapper: {
    "& .gx-popover-menu-list": {
      left: "initial",
      right: 0,
      top: 48,
      "& ul li a": {
        padding: "9px 16px 10px",
        textAlign: "left",
      },
      "& li.gx-menu-item-permanent-delete a": {
        color: colorPalette.status.poisonCherry.hex,
      },
    },
  },
};

export default surveyCardItemStyles;
